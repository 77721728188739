/*global $*/

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['handle', 'resizeImage'];

  checkPosition() {
    const container = $(this.element);
    if ($(window).scrollTop() + $(window).height() * 0.5 > container.offset().top) {
      container.addClass('is-visible');
    }
  }

  connect() {
    this.checkPosition();

    const dragElement = $(this.handleTarget);
    const resizeElement = $(this.resizeImageTarget);
    const container = $(this.element);

    dragElement
      .on('mousedown vmousedown', function (e) {
        dragElement.addClass('compare-draggable');
        resizeElement.addClass('resizable');

        var dragWidth = dragElement.outerWidth(),
          xPosition = dragElement.offset().left + dragWidth - e.pageX,
          containerOffset = container.offset().left,
          containerWidth = container.outerWidth(),
          minLeft = containerOffset + 10,
          maxLeft = containerOffset + containerWidth - dragWidth - 10;

        dragElement
          .parents()
          .on('mousemove vmousemove', function (e) {
            var leftValue = e.pageX + xPosition - dragWidth;

            //constrain the compare-draggable element to move inside his container
            if (leftValue < minLeft) {
              leftValue = minLeft;
            } else if (leftValue > maxLeft) {
              leftValue = maxLeft;
            }

            var widthValue = ((leftValue + dragWidth / 2 - containerOffset) * 100) / containerWidth + '%';

            $('.compare-draggable')
              .css('left', widthValue)
              .on('mouseup vmouseup', function () {
                $(this).removeClass('compare-draggable');
                resizeElement.removeClass('resizable');
              });

            $('.resizable').css('width', widthValue);
          })
          .on('mouseup vmouseup', function (e) {
            dragElement.removeClass('compare-draggable');
            resizeElement.removeClass('resizable');
          });
        e.preventDefault();
      })
      .on('mouseup vmouseup', function (e) {
        dragElement.removeClass('compare-draggable');
        resizeElement.removeClass('resizable');
      });
  }
}
