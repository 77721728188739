import { Controller } from '@hotwired/stimulus';
import hcOffcanvasNav from 'hc-offcanvas-nav';

export default class extends Controller {
  static targets = ['nav', 'toggle'];

  connect() {
    this.nav = new hcOffcanvasNav(this.navTarget, {
      customToggle: this.toggleTarget,
      width: 400,
    });
  }
}
