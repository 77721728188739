import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];
  static values = {
    expanded: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    this.element.classList.toggle('expanded', this.expandedValue);
    this.isExpanded = this.expandedValue;
    this.itemTargets.forEach((item) => {
      if (this.isExpanded) {
        item.style.height = item.scrollHeight + 'px';
      } else {
        item.style.height = '0px';
      }
    });
  }

  toggle(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.itemTargets.forEach((item) => {
      // have the element transition to the height of its inner content
      item.style.height = item.scrollHeight + 'px';
    });
    this.isExpanded = true;
    this.element.classList.toggle('expanded', this.isExpanded);
  }

  hide() {
    this.itemTargets.forEach((item) => {
      const sectionHeight = item.scrollHeight;
      const elementTransition = item.style.transition;
      item.style.transition = '';
      requestAnimationFrame(() => {
        item.style.height = sectionHeight + 'px';
        item.style.transition = elementTransition;
        requestAnimationFrame(() => {
          item.style.height = 0 + 'px';
        });
      });
      // const expanded = !item.classList.contains(this.hiddenClassValue);
      // //item.classList.toggle(this.hiddenClassValue);
      // item.classList.toggle('rotate-90');
      // //if (this.expandable) {
      // //  this.expanded = !this.expanded;
      // //  this.$refs.toggle.classList.toggle('rotate-90');
      // // const element = this.$refs.collapsableContent;
      // if (!expanded) {
      //   const sectionHeight = item.scrollHeight;

      //   // have the element transition to the height of its inner content
      //   item.style.height = sectionHeight + 'px';
      // } else {
      //   const sectionHeight = item.scrollHeight;
      //   const elementTransition = item.style.transition;
      //   item.style.transition = '';
      //   requestAnimationFrame(() => {
      //     item.style.height = sectionHeight + 'px';
      //     item.style.transition = elementTransition;
      //     requestAnimationFrame(() => {
      //       item.style.height = 0 + 'px';
      //     });
      //   });
      // }
      //}
    });
    this.isExpanded = false;
    this.element.classList.toggle('expanded', this.isExpanded);
  }
}
