import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';
import { TomInput, TomSettings } from 'tom-select/dist/types/types';

export default class extends Controller {
  declare select: TomSelect;
  declare optionsValue: Object;

  static values = {
    options: Object,
  };

  connect() {
    this.select = new TomSelect(
      this.element as TomInput,
      {
        ...this.defaultOptions,
        ...this.optionsValue,
      } as TomSettings
    );
  }

  disconnect() {
    if (this.select != null) {
      this.select.destroy();
    }
  }

  get defaultOptions() {
    return {};
  }
}
