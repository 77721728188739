import { Controller } from '@hotwired/stimulus';
const YTPlayer = require('yt-player');

export default class extends Controller {
  static targets = ['container'];
  static values = { video: String };

  play() {
    this.containerTarget.style.display = 'block';
    this.containerTarget.classList.toggle('hidden', false);
    this.player = new YTPlayer(this.containerTarget, { width: '100%', height: 480, fullscreen: true });

    this.player.load(this.videoValue);
    this.player.setVolume(100);
  }

  disconnect() {
    this.stop();
  }

  stop() {
    if (this.player != null) {
      this.player.destroy();
    }
    this.containerTarget.style.display = 'none';
    this.containerTarget.classList.toggle('hidden', true);
  }
}
