import { Camera, Scene, WebGLRenderer } from 'three';
import Vue, { VNode } from 'vue';

interface RendererInterface {
  renderer?: WebGLRenderer;
  camera?: Camera;
  scene?: Scene;
}

export const Renderer = Vue.extend({
  name: 'Renderer',
  props: {
    antialias: { type: Boolean, required: false, default: false },
    pixelRatio: { type: Number },
    width: { type: String },
    height: { type: String },
  },
  data(): RendererInterface {
    return {};
  },
  methods: {},
  mounted() {
    this.renderer = new WebGLRenderer({
      antialias: this.$props.antialias,
    });
    if (this.$props.pixelRatio) {
      this.renderer.setPixelRatio(this.$props.pixelRatio);
    } else {
      this.renderer.setPixelRatio(window.devicePixelRatio);
    }
  },
  provide() {
    return {
      renderer: this,
    };
  },
  render(createElement): VNode {
    return createElement('div', {}, this.$slots.default);
  },
});
