import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);
SwiperCore.use([Pagination]);

export default class extends Controller {
  static values = {
    options: Object,
  };

  connect() {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions() {
    return {
      autoplay: {
        delay: 3000,
      },
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };
  }
}
