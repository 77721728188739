import Vue, { VNode } from 'vue';
import { Color, GridHelper as ThreeGridHelper, Texture } from 'three';

export const GridHelper = Vue.extend({
  name: 'GridHelper',
  inject: ['scene'],
  props: {
    size: { type: Number, required: false, default: 10 },
    division: { type: Number, required: false, default: 10 },
    colorCenterLine: { type: [String, Number, Object], required: false },
    colorGrid: { type: [String, Number, Object], required: false },
  },
  mounted() {
    if (!this.scene) {
      throw new Error("Scene not found. Most likely it hasn't been included in the list of dependencies");
    }
    const grid = new ThreeGridHelper(this.$props.size, this.$props.division, this.$props.colorCenterLine, this.$props.colorGrid);
    this.scene.add(grid);
  },
  render(createElement): VNode {
    return createElement('div', {}, this.$slots.default);
  },
});
