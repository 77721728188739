import { Camera, Object3D, Raycaster, Vector2 } from 'three';

export class SelectionHelper {
  private readonly raycaster: Raycaster;
  private selectionEnabled: boolean;

  declare pickedObject: Object3D | null;

  constructor() {
    this.raycaster = new Raycaster();
    this.pickedObject = null;
    this.selectionEnabled = true;
  }

  disableSelection(): void {
    this.selectionEnabled = false;
  }

  enableSelection(): void {
    this.selectionEnabled = true;
  }

  selectedObject(): Object3D | null {
    return this.pickedObject;
  }

  pick(normalizedPosition: Vector2, objects: Object3D[], camera: Camera, callback) {
    if (!this.selectionEnabled) {
      return;
    }
    this.raycaster.setFromCamera(normalizedPosition, camera);

    const intersections = this.raycaster.intersectObjects(objects, true);

    const previousObject = this.pickedObject;
    if (intersections.length > 0) {
      this.pickedObject = intersections[0].object;
    } else {
      this.pickedObject = null;
    }
    const currentObject = this.pickedObject;
    callback(currentObject, previousObject);
  }
}
