import { Controller } from '@hotwired/stimulus';

function scrollTo(element) {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: element.getBoundingClientRect().top + window.scrollY,
  });
}

export default class extends Controller {
  connect() {
    this.scrollHere();
  }
  /**
   * Scroll to the current element
   */
  scrollHere() {
    scrollTo(this.element);
  }
}
