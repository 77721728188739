import Vue, { VNode } from 'vue';
import { Color, Object3D, Scene as ThreeScene, Texture } from 'three';

interface SceneInterface {
  scene?: ThreeScene;
}

export const Scene = Vue.extend({
  name: 'Scene',
  inject: ['renderer'],
  props: {
    background: { type: [String, Number, Object], required: false },
  },
  data(): SceneInterface {
    return {};
  },
  methods: {
    add(o: Object3D) {
      this.scene?.add(o);
    },
    remove(o: Object3D) {
      this.scene?.remove(o);
    },
  },
  created() {
    if (!this.renderer) {
      throw new Error("Renderer not found. Most likely it hasn't been included in the list of dependencies");
    }

    this.scene = new ThreeScene();
    this.renderer.scene = this.scene;
  },
  mounted() {
    const scene = this.scene;
    const setBackground = (value: any): void => {
      if (!value) return;
      if (typeof value === 'string' || typeof value === 'number') {
        if (scene.background instanceof Color) scene.background.set(value);
        else scene.background = new Color(value);
      } else if (value instanceof Texture) {
        scene.background = value;
      }
    };

    setBackground(this.$props.background);
  },
  provide() {
    return {
      scene: this,
    };
  },
  render(createElement): VNode {
    return createElement('div', {}, this.$slots.default);
  },
});
