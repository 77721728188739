import { PerspectiveCamera as ThreePerspectiveCamera } from 'three';
import Vue, { PropType } from 'vue';
import { Camera } from './Camera';
import { Vector3PropInterface } from './types';

export const PerspectiveCamera = Vue.extend({
  name: 'PerspectiveCamera',
  extends: Camera,
  inject: ['renderer'],
  props: {
    aspect: { type: Number, default: 1 },
    position: { type: Object as PropType<Vector3PropInterface>, default: () => ({ x: 0, y: 0, z: 0 }) },
    lookAt: { type: Object as PropType<Vector3PropInterface>, default: null },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    if (!this.renderer) {
      throw new Error("Renderer not found. Most likely it hasn't been included in the list of dependencies");
    }
    const camera = new ThreePerspectiveCamera(30, this.$props.aspect, 1, 10000);
    this.renderer.camera = camera;

    // TODO add reactivity to the position property
    camera.position.set(this.$props.position.x, this.$props.position.y, this.$props.position.z);

    if (this.$props.lookAt) {
      camera.lookAt(this.$props.lookAt.x ?? 0, this.$props.lookAt.y, this.$props.lookAt.z);
    }
  },
});
