import Select from '../../controllers/select_controller';

export default class extends Select {
  get defaultOptions() {
    return {
      onItemAdd: (value, item) => {
        if (item.dataset.url != null) {
          window.open(item.dataset.url, '_self');
        }
      },
      render: {
        item: (item, escape) => {
          return this.itemHTML(item, escape);
        },
        option: (item, escape) => {
          return this.itemHTML(item, escape);
        },
      },
    };
  }

  itemHTML(item, escape) {
    return `<div data-url="${escape(item.url)}">
      <img src="${item.image}" />
      ${escape(item.text)}</div>`;
  }
}
