import Carousel from './carousel_controller';

export default class extends Carousel {
  get defaultOptions() {
    return {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          const thumbnail = this.element.querySelectorAll('.swiper-slide').item(index).dataset.thumbnail;
          return '<div class="' + className + '"><img src="' + thumbnail + '"/></div>';
        },
      },
      autoplay: {
        delay: 3000,
      },
      loop: true,
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 20,
    };
  }
}
